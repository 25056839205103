import React from "react";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { HashLink as Link } from "react-router-hash-link";
import "./Footer.css";

function Footer() {
  return (
    <>
      <div className="footer-main-container footer-svg">
        <div class="custom-shape-divider-top-1630012056">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              class="shape-fill"
            ></path>
          </svg>
        </div>
        <div className="footer-top-container">
          <div className="footer-top-left-container">
            <div className="footer-office-title">Office Adress</div>
            <div className="footer-office-description">
              Herby Fruit Snacks Totowa Business Center 999 Riverview Drive,
              Suite 201 Totowa, New Jersey, 07512 USA
            </div>
            <div className="footer-icons-container">
              <FaTwitter size={40} color="#055052" className="footer-icon" />
              <FaFacebookF size={40} color="#055052" className="footer-icon" />
              <FaInstagram size={40} color="#055052" className="footer-icon" />
            </div>
          </div>
          <div className="footer-top-mid-container">
            <div className="footer-navigator-container">
              <div className="footer-navigator">
                <div className="footer-link">
                  <Link
                    className="nav-footer-links"
                    to="/#home"
                    smooth={true}
                    duration={500}
                  >
                    Home
                  </Link>
                </div>
                <div className="footer-link">
                  <Link
                    className="nav-footer-links"
                    to="/#products"
                    smooth={true}
                    duration={500}
                  >
                    Products
                  </Link>
                </div>
              </div>
              <div className="footer-navigator">
                <div className="footer-link">
                  <Link
                    className="nav-footer-links"
                    to="/#about-us"
                    smooth={true}
                    duration={500}
                  >
                    About Us
                  </Link>
                </div>
                <div className="footer-link">
                  <Link
                    className="nav-footer-links"
                    to="/#contact-us"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-top-right-container">
            <img
              alt={"footer-herby-snacks-logo"}
              src={process.env.PUBLIC_URL + "/images/picture-logo.png"}
              className="footer-logo"
            ></img>
          </div>
        </div>
        <div className="footer-bottom-container">
          <div className="footer-bottom-motto">
            Happiness Multiplies When Shared
          </div>
          <div className="footer-bottom-copyright">
            © 2021 Herby Snacks |{" "}
            <a href="https://www.promising.technology/" className="ref-link">
              Promising Technology
            </a>
          </div>
          <a href="https://emretelli.net" className="ref-link">
            Developed by Emre T.
          </a>
        </div>
      </div>
    </>
  );
}

export default Footer;
