export const homeIntroObj = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Completely Vegan",
  headline: "Happiness Multiplies When Shared",
  description: "",
  buttonLabel: "Contact Us",
  imgStart: "images/redherby.png",
  img: "/images/redherby.png",
  alt: "",
};

export const homeProductsObj = {
  firstProductImg: "/images/redherby.png",
  secondProductImg: "/images/yellowherby.png",
  firstProductDetails:
    "Herby Fruit Snacks, made with the real flavors of Apple, Strawberry, Orange, Pomegranate...",
  secondProductDetails:
    "Made with the real flavors of Apple, Strawberry, Orange, Pomegranate...",
  firstProductLink: "/product/10/herby-fruit-snacks-red",
  secondProductLink: "/product/11/herby-fruit-snacks-yellow",
  buttonLabel: "Details",
};

export const homeMottoObj = {
  mottoTitle: "",
  mottoDesc:
    "Herby Snacks aims to offer healthy and delicious products. The products are developed using high quality ingredients. Herby Snacks is dedicated to those who love to share, highlighting the vegan concept.",
  mottoImg: "/images/herby_snack_flyer-2.png",
};

export const homeAboutUsObj = {
  aboutTitle: "Who We Are?",
  aboutDesc:
    "Herby is a cute and friendly character. He is focused on being a good individual and to help everyone around him with the motto, “Happiness multiplies when shared”. A portion of the income made from Herby products will be used to bring his motto to life.",
  aboutImg: "/images/herby_molds.jpg",
};

export const homeContactUsObj = {
  contactMail: "info@herbysnacks.com",
};
