import React from "react";
import "./MottoSection.css";

function ProductsSection({ mottoTitle, mottoDesc, mottoImg }) {
  return (
    <>
      <div className="home__motto-section-row">
        <div className="home__motto-col orangeBg">
          <div className="home__motto-image-container svg">
            <img
              alt={"herbysnacks-products-packaging"}
              src={mottoImg}
              className="home__motto-image"
            />
          </div>
        </div>
        <div className="home__motto-col svg">
          <div className="home__motto-text-container">
            <div className="home__motto-title">{mottoTitle}</div>
            <div className="home__motto-desc">{mottoDesc}</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductsSection;
