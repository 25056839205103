import React from "react";
import "./HeroSection.css";

function HeroSection({
  topLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  imgStart,
}) {
  return (
    <>
      <div class="product__hero-section product__intro-svg">
        <div className="product__container">
          <div className="product__row">
            <div className="product__logo-container">
              <a href={"/"}>
                <img
                  alt={"herby-snacks-logo"}
                  className="product__logo"
                  src="/images/logo.png"
                ></img>
              </a>
            </div>
          </div>
          <div
            className="product__ product__hero-row"
            style={{
              display: "flex",
              flexDirection: imgStart === "start" ? "row-reverse" : "row",
            }}
          >
            <div className="product__col">
              <div className="product__hero-text-wrapper">
                <h1
                  className={
                    lightText ? "product__heading" : "product__heading dark"
                  }
                >
                  {headline}
                </h1>
                <p
                  className={
                    lightTextDesc
                      ? "product__hero-subtitle"
                      : "product__hero-subtitle dark"
                  }
                >
                  {description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
