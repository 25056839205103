import React from "react";
import { Button } from "../../../Button";
import { Link } from "react-router-dom";
import "./ProductsSection.css";

function ProductsSection({
  firstProductImg,
  secondProductImg,
  firstProductDetails,
  secondProductDetails,
  firstProductLink,
  secondProductLink,
  buttonLabel,
}) {
  return (
    <>
      <div className="home__products-section-row">
        <div className="home__products-section-title">Our Products</div>
      </div>
      <div className="home__products-section-row">
        <div className="home__products-col">
          <div className="home__product-container">
            <div className="home__image-container">
              <img
                alt={"herby-snacks-fruit-snacks-product"}
                className="home__product-image"
                src={firstProductImg}
              ></img>
            </div>
            <div className="home__product-bottom-container">
              <div className="home__product-text-wrapper">
                <div className="home_product-title">Fruit Snacks</div>
                <div className="home__product-description">
                  {firstProductDetails}
                </div>
              </div>
              <div className="home__product-button-container">
                <Link to={firstProductLink}>
                  <Button
                    shadow={true}
                    buttonSize="btn--wide"
                    buttonColor="gradient-orange"
                  >
                    {buttonLabel}
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="home__products-col">
          <div className="home__product-container">
            <div className="home__image-container">
              <img
                alt={"herby-snacks-sour-fruit-snacks-product"}
                className="home__product-image"
                src={secondProductImg}
              ></img>
            </div>
            <div className="home__product-bottom-container">
              <div className="home__product-text-wrapper">
                <div className="home_product-title">Sour Fruit Snacks</div>
                <div className="home__product-description">
                  {secondProductDetails}
                </div>
              </div>
              <div className="home__product-button-container">
                <Link to={secondProductLink}>
                  <Button
                    shadow={true}
                    buttonSize="btn--wide"
                    buttonColor="gradient-orange"
                  >
                    {buttonLabel}
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductsSection;
