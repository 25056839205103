import React from "react";
import "./AboutSection.css";

function ProductsSection({ aboutTitle, aboutDesc, aboutImg }) {
  return (
    <>
      <div className="home__about-section-row">
        <div className="home__about-image-container">
          <img
            alt={"picture-logo.png"}
            src={"images/picture-logo.png"}
            className="home__about-picture-logo"
          ></img>
        </div>
      </div>
      <div className="home__about-section-row">
        <div className="home__about-text-container">
          <div className="home__about-title">{aboutTitle}</div>
          <div className="home__about-desc">{aboutDesc}</div>

          <img
            alt={"herbysnacks-gummy"}
            src={aboutImg}
            className="home__about-image"
          ></img>
        </div>
      </div>
    </>
  );
}

export default ProductsSection;
