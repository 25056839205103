import React from "react";
import "./ProductSection.css";
import { FaCheck } from "react-icons/fa";

function ProductSection(data) {
  return (
    <>
      <div className="product__product-section">
        <div className="product__product-section-row">
          <div className="product__product-up-container">
            <div className="product__col-img">
              <div className="product__product-image-container">
                <img
                  alt={data[data.id].img + "image"}
                  src={process.env.PUBLIC_URL + data[data.id].img}
                />
              </div>
            </div>
            <div className="product__col-text">
              <div className="product__product-ingredients-title">
                INGREDIENTS:
              </div>
              <div className="product__product-ingredients-text">
                {data[data.id].ingredients}
                <div className="product__product-check-list">
                  <div>
                    <FaCheck color="#3cbc8d" /> Vegan
                  </div>
                  <div>
                    <FaCheck color="#3cbc8d" /> Gluten Free
                  </div>
                  <div>
                    <FaCheck color="#3cbc8d" /> GMO Free
                  </div>
                  <div>
                    <FaCheck color="#3cbc8d" /> Natural Flavors & Colors
                  </div>
                  <div>
                    <FaCheck color="#3cbc8d" /> Made With Organic Fruit Juice
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="product_product-section-row">
          <div className="product__product-bottom-container">
            <div className="product__product-title-container">
              <div className="product__product-title">{data[data.id].name}</div>
            </div>
            <div className="product__product-desc-container">
              <div className="product__product-desc">{data[data.id].desc}</div>
            </div>
            <div className="product__bottom-ingredients">
              <div className="product__product-ingredients-title">
                INGREDIENTS:
              </div>
              <div className="product__product-ingredients-text">
                {data[data.id].ingredients}
                <div className="product__product-check-list">
                  <div>
                    <FaCheck color="#3cbc8d" /> Vegan
                  </div>
                  <div>
                    <FaCheck color="#3cbc8d" /> Gluten Free
                  </div>
                  <div>
                    <FaCheck color="#3cbc8d" /> GMO Free
                  </div>
                  <div>
                    <FaCheck color="#3cbc8d" /> Natural Flavors & Colors
                  </div>
                  <div>
                    <FaCheck color="#3cbc8d" /> Made With Organic Fruit Juice
                  </div>
                </div>
              </div>
            </div>
            <div className="product__product-backimg-container">
              <div className="product__product-backimg-title">
                Back Of Packaging
              </div>
              <img
                className="product_product-backimg"
                alt={data[data.id].img + "image-back"}
                src={process.env.PUBLIC_URL + data[data.id].backImg}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductSection;
