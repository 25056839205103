import React from "react";
import { Button } from "../../../Button";
import { HashLink as Link } from "react-router-hash-link";
import "./IntroSection.css";

function HeroSection({
  topLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  imgStart,
}) {
  return (
    <>
      <div class=" home__hero-section intro-svg">
        <div className="container">
          <div className="row">
            <div className="logo-container">
              <a href={"/"}>
                <img
                  alt={"herbysnacks-logo"}
                  className="logo"
                  src="/images/logo.png"
                ></img>
              </a>
            </div>
          </div>
          <div
            className="row home__hero-row"
            style={{
              display: "flex",
              flexDirection: imgStart === "start" ? "row-reverse" : "row",
            }}
          >
            <div className="col">
              <div className="home__hero-text-wrapper">
                <div className="top-line">{topLine}</div>
                <h1 className={lightText ? "heading" : "heading dark"}>
                  {headline}
                </h1>
                <p
                  className={
                    lightTextDesc
                      ? "home__hero-subtitle"
                      : "home__hero-subtitle dark"
                  }
                >
                  {description}
                </p>
                <Link to="/#contact-us" smooth={true} duration={500}>
                  <Button
                    shadow={true}
                    buttonSize="btn--wide"
                    buttonColor="gradient-orange"
                  >
                    {buttonLabel}
                  </Button>
                </Link>
              </div>
            </div>
            <div className="col">
              <div className="home__hero-img-wrapper">
                <img src={img} alt={alt} className="home__hero-img"></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
