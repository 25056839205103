import React, { useState } from "react";
import { send } from "emailjs-com";
import "./ContactSection.css";
import BounceLoader from "react-spinners/BounceLoader";

function ContactSection({ aboutTitle, aboutDesc, aboutImg }) {
  const [loading, setLoading] = useState(false);
  const [isSend, setIsSend] = useState(false);

  const [toSend, setToSend] = useState({
    from_name: "",
    to_name: "HerbySnacks",
    message: "",
    reply_to: "",
    phone_from: "",
  });

  const [error, setError] = useState(true);

  const onSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    send(
      "service_ekveqzq",
      "template_jrmpcfe",
      toSend,
      "user_8fgKlFYfoPn7y8p1ErR9l"
    )
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        setLoading(false);
        setIsSend(true);
      })
      .catch((err) => {
        console.log("FAILED...", err);
      });
  };

  const handleChange = (e) => {
    if (e.target.value === null || e.target.value === "") {
      setError(true);
    }
    if (
      toSend.from_name === "" ||
      toSend.from_name === null ||
      toSend.message === "" ||
      toSend.message === null ||
      toSend.reply_to === "" ||
      toSend.reply_to === null
    ) {
      setError(true);
    } else {
      setError(false);
    }
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div className="home__contact-section">
        <div className="home__contact-section-container contact-svg">
          <div className="home__contact-section-row">
            <div className="contact-title">Contact Us</div>
          </div>
          <form onSubmit={onSubmit} className="home__contact-section-row">
            <div className="home__contact-section-col">
              <input
                className="input"
                type="text"
                id="name"
                name="from_name"
                placeholder="Name *"
                value={toSend.from_name}
                onChange={handleChange}
              />
              <input
                className="input"
                type="text"
                id="email"
                name="reply_to"
                placeholder="E-mail *"
                value={toSend.reply_to}
                onChange={handleChange}
              />
              <input
                className="input"
                type="text"
                id="phone"
                name="phone_from"
                placeholder="Phone Number"
                value={toSend.phone_from}
                onChange={handleChange}
              />
            </div>
            <div className="home__contact-section-col">
              <textarea
                className="input messagebox"
                type="text"
                id="message"
                name="message"
                placeholder="Your Message Here... *"
                value={toSend.message}
                onChange={handleChange}
              />
              {loading ? (
                <div>
                  <BounceLoader color={"green"} loading={loading} size={50} />
                </div>
              ) : isSend ? (
                <input
                  className="submit-button"
                  type="submit"
                  value={"Done!"}
                  disabled={true}
                />
              ) : (
                <input
                  className="submit-button"
                  type="submit"
                  value={"Send"}
                  disabled={error}
                />
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ContactSection;
