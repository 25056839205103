export const productHeroObj = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  headline: "Happiness Multiplies When Shared",
  description: "",
};

export const productProductsObj = {
  10: {
    name: "Fruit Snacks",
    ingredients:
      "Sugar, Glucose Syrup, Organic Apple Juice Concantrate(%10), Modified Potato Starch, Acidity Regulator (Citrit, Acid, Malic Acid), Flavourings (Strawberry, Pomegranate, Apple, Peach, Melon, Orange), Spirulina Extract, Beet Root Juice Concentrate, Natural Colours (Turmeric, Anthocyanin Beta Carotene)",
    desc: "Herby Fruit Snacks, made with the real flavors of Apple, Strawberry, Orange, Pomegranate, Melon and Peach fruits, has been carefully prepared for you.",
    img: "/images/redherby.png",
    backImg: "/images/redherbyback.png",
  },
  11: {
    name: "Sour Fruit Snacks",
    ingredients:
      "Sugar, Glucose Syrup, Organic Apple Juice Concantrate(%10), Modified Potato Starch, Acidity Regulator (Citrit, Acid, Malic Acid), Flavourings (Strawberry, Pomegranate, Apple, Peach, Melon, Orange), Spirulina Extract, Beet Root Juice Concentrate, Natural Colours (Turmeric, Anthocyanin Beta Carotene)",
    desc: "Made with the real flavors of Apple, Strawberry, Orange, Pomegranate, Melon and Peach fruits, Herby Fruit Snacks has been carefully prepared for you with its sour flavor.",
    img: "/images/yellowherby.png",
    backImg: "/images/yellowherbyback.png",
  },
};
