import React, { useEffect } from "react";
import HeroSection from "./Section/HeroSection";
import { productHeroObj, productProductsObj } from "./Data";
import ProductSection from "./Section/ProductSection";
import useWindowDimensions from "../../functions/useWindowDimensions";

export default function Product({ match }) {
  const { width } = useWindowDimensions();
  useEffect(() => {
    if (width < 900) {
      window.scrollTo(0, 850);
    } else {
      window.scrollTo(0, 500);
    }
  }, []);

  return (
    <>
      <HeroSection {...productHeroObj} />
      <ProductSection {...productProductsObj} {...match.params} />
    </>
  );
}
