import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "./Navbar.css";
import { IconContext } from "react-icons/lib";
import { HashLink as Link } from "react-router-hash-link";

function Navbar() {
  const [click, setClick] = useState(false);

  const closeMobileMenu = () => setClick(false);

  return (
    <>
      <IconContext.Provider value={{ color: click ? "white" : "#f00946" }}>
        <div className={click ? "navbar active" : "navbar"}>
          <div
            className={
              click ? "menu-icon-container active" : "menu-icon-container"
            }
          >
            <div className="menu-icon" onClick={() => setClick(!click)}>
              {click ? <FaTimes /> : <FaBars style={{ color: "black" }} />}
            </div>
          </div>
          <ul
            className={
              click ? "navbar-menu-container active" : "navbar-menu-container"
            }
          >
            <li className="nav-item">
              <Link
                onClick={closeMobileMenu}
                className="nav-links redText"
                to="/#home"
                smooth={true}
                duration={500}
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                onClick={closeMobileMenu}
                className="nav-links redText"
                to="/#products"
                smooth={true}
                duration={500}
              >
                Products
              </Link>
            </li>
            <li className="nav-item">
              <Link
                onClick={closeMobileMenu}
                className="nav-links redText"
                to={"/#about-us"}
                smooth={true}
                duration={500}
              >
                About Us
              </Link>
            </li>
            <li className="nav-item redBg ">
              <Link
                onClick={closeMobileMenu}
                className="nav-links light"
                to="/#contact-us"
                smooth={true}
                duration={500}
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
