import React from "react";
import IntroSection from "./Section/IntroSection";
import ProductsSection from "./Section/ProductsSection";
import MottoSection from "./Section/MottoSection";
import AboutSection from "./Section/AboutSection";
import ContactSection from "./Section/ContactSection";
import {
  homeAboutUsObj,
  homeIntroObj,
  homeMottoObj,
  homeProductsObj,
  homeContactUsObj,
} from "./Data";

function Home() {
  return (
    <>
      <div id="home">
        <IntroSection {...homeIntroObj} />
      </div>
      <div id="products">
        <ProductsSection {...homeProductsObj} />
      </div>
      <MottoSection {...homeMottoObj} />
      <div id="about-us">
        <AboutSection name="about-us" {...homeAboutUsObj} />
      </div>
      <div id="contact-us">
        <ContactSection {...homeContactUsObj} />
      </div>
    </>
  );
}

export default Home;
